exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jewelry-js": () => import("./../../../src/pages/jewelry.js" /* webpackChunkName: "component---src-pages-jewelry-js" */),
  "component---src-pages-magazine-js": () => import("./../../../src/pages/magazine.js" /* webpackChunkName: "component---src-pages-magazine-js" */),
  "component---src-pages-series-js": () => import("./../../../src/pages/series.js" /* webpackChunkName: "component---src-pages-series-js" */),
  "component---src-templates-jewelry-js": () => import("./../../../src/templates/jewelry.js" /* webpackChunkName: "component---src-templates-jewelry-js" */),
  "component---src-templates-magazine-js": () => import("./../../../src/templates/magazine.js" /* webpackChunkName: "component---src-templates-magazine-js" */),
  "component---src-templates-series-js": () => import("./../../../src/templates/series.js" /* webpackChunkName: "component---src-templates-series-js" */)
}

